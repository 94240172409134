import Modules from './components/Modules.vue';

bcore.provide('bcore.modules');

export class Home {
	constructor({ parent }) {
		this.$parent = $(parent);
		this.page = parent.page;
		this.setup_header();
		this.make_body();
	}
	
	make_body() {
		this.$modules_container = this.$parent.find('.layout-main');

		new Vue({
			el: this.$modules_container[0],
			render: h => h(Modules)
		});
	}

	setup_header() {
		// subtitle
	}
};

bcore.modules.Home = Home;